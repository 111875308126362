/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import Footer from "./footer";
import "../styles/reset.css";

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Whether you help make, move or sell our products, you all play a unique part in our growth and success, so we believe it’s only right that you are ‘Being Rewarded’ with the opportunity to own your share of our unique company."
        />
        <title>Coca Cola Shareplan</title>
      </Helmet>
      <div
        style={{
          margin: `0 auto`,
          paddingTop: 0
        }}
      >
        <main>{children}</main>
      </div>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
