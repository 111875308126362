import PropTypes from "prop-types";
//import React from "react";
import React, { Component } from "react";
import logo from "../images/cc-europacific-logo.png";
import { Link, animateScroll as scroll } from "react-scroll";

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = { downloadLinkOffset: 0 };
  }

  componentDidMount() {
    window.addEventListener("load", () => this.setDownloadLinkOffset());
    window.addEventListener("resize", () => this.setDownloadLinkOffset());
    this.setDownloadLinkOffset();
  }
  setDownloadLinkOffset() {
    this.setState({ downloadLinkOffset: window.innerHeight < 850 ? 0 : -600 });
  }
  render() {
    return (
      <nav
        className={`cola-shareplan__nav flex items-center justify-between px-12 bg-black text-white cola-shareplan__nav--${this.props.nav}`}
      >
        <img
          src={logo}
          className="logo"
          width="223"
          alt="Coca Cola Europacific Partners"
        />
        {this.props.nav === "header" ? (
          <a
            // href="https://www.shareworks.com"
            target="_blank"
            className="uppercase border-white border-solid border-2 cola-shareplan__login"
            onClick={() => this.props.handleClick()}
          >
            Join / Log-In
          </a>
        ) : null}

        {this.props.nav === "header" ? (
          <ul className="items-center">
            <li className="mr-5">
              <Link
                activeClass="active"
                to="overview"
                spy={true}
                smooth={true}
                offset={-120}
                duration={100}
              >
                Overview
              </Link>
            </li>
            <li className="mr-5">
              <Link
                activeClass="active"
                to="features"
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Features
              </Link>
            </li>
            <li className="mr-5">
              <Link
                className="joining"
                activeClass="active"
                to="joining"
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Joining
              </Link>
            </li>
            {/* <li className="mr-5">
              <Link
                activeClass="active"
                to="testimonials"
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
              >
                Testimonials
              </Link>
            </li>
            <li className="mr-5">
              <Link
                className="shareshop"
                activeClass="active"
                to="shareshop"
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
                // onSetInactive={() => {
                //   if (!document.querySelector(".shareshop")) return;
                //   document
                //     .querySelector(".shareshop")
                //     .classList.remove("active");
                // }}
              >
                ShareShop
              </Link>
            </li> */}
            <li>
              <Link
                activeClass="active"
                to="downloads"
                spy={true}
                smooth={true}
                offset={this.state.downloadLinkOffset}
                duration={500}
                onSetActive={() => {
                  if (!document.querySelector(".joining")) return;
                  document.querySelector(".joining").classList.remove("active");
                }}
                onSetInactive={() => {
                  if (!document.querySelector(".joining")) return;
                  document.querySelector(".joining").classList.add("active");
                }}
              >
                Downloads
              </Link>
            </li>
          </ul>
        ) : (
          <ul className="items-center m-0 justify-end">
            <li className="mr-5"></li>
          </ul>
        )}
      </nav>
    );
  }
}

export default Nav;

Nav.propTypes = {
  nav: PropTypes.string
};

Nav.defaultProps = {
  nav: "header"
};
