import React from "react";
import Nav from "./nav";

const Footer = () => (
  <footer>
    <Nav nav="footer" />
  </footer>
);

export default Footer;
